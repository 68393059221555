.container{
    background-color: #ECECEC;
    border: 0.5px solid #8E8A89;
    border-radius: 10px;
    position: absolute;
    left: 50%;
    margin-top: 10px;
    margin-right: 15px;
    max-width: 30%;
    min-width: 300px;
    padding: 40px 40px 10px 40px;
    top: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 7px 4px 15px 0px black;



}

.imagen-fondo{
    background-image: url("../../img/inventario.jpg");
    background-color: #cccccc;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh; 

}

.login-container{
    
    text-align: center;
}

#root{
    height: 100%;
}

