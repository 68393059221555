.sidebar {
  margin: 0;
  padding: 0;
  min-height: 100vh;
}
.sidebar-user-name {
  color: white;
  padding-left: 35px;
  text-align: center;
}
