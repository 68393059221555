.navbar {

  padding-top: 1rem;
  padding-bottom: 1rem;
  background: #001529;
  display: flex; 
}

@media (min-width: 992px) {
  .menu {
    display: none !important;
  }
}
.dark-mode-aux .ant-drawer-content,
.dark-mode-aux .ant-drawer-header,
.dark-mode-aux .ant-drawer-title,
.dark-mode-aux .anticon-close {
  background: #001529 !important;
  color: white !important;
}
.logo {
  height: 32px;
  margin-left: 1rem;
}

.btn-menu-outline{
  background: #001529 !important;
  color: white !important;
}
.ant-drawer-body{
background: #001529;
}